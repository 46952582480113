<template>
  <!-- Start Single Service  -->
  <div class="row mt_dec--30">
    <v-row>
    <v-col :cols=comNumColumnas class="mt--30" v-for="(service, i) in serviciosPublicados()" :key="i">
      <div :class="'single-service service__style-notin bg-color-gray card-' + type + '-notin'">
        <NuxtLink :to="'/servicios/' + service.Slug">
          <div class="service">
            <div class="content">
              <h3 class="heading-title">
                {{ service.Titulo }}
              </h3>
              <p>
                {{ service.Extracto }}
              </p>
            </div>
          </div>
        </NuxtLink>
      </div>
    </v-col>
    </v-row>
  </div>
  <!-- End Single Service  -->
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '../../store/index'
const { NOTARIA } = useStore()
import { useUtils } from '#imports';
const { imagenIDtoSRC, $t } = useUtils()

import { useStyles } from '../../store/styles';
const style = useStyles();
const props = defineProps({
  Home: Boolean,
  type: String
})

const comNumColumnas = computed(() => {
  if (NOTARIA.Theme.Servicios.NumeroColumnas == 1) {
    return 12
  } else if (NOTARIA.Theme.Servicios.NumeroColumnas == 2) {
    return 6
  } else if (NOTARIA.Theme.Servicios.NumeroColumnas == 3) {
    return 4
  } else if (NOTARIA.Theme.Servicios.NumeroColumnas == 4) {
    return 3
  }
})

function serviciosPublicados(){
  if(props.Home)
  {
    return NOTARIA.Servicios.filter(s=>s.Publicado && s.MostrarHome)
  }else{
    return NOTARIA.Servicios.filter(s=>s.Publicado)
  }
}

const estiloTarjetaTituloColor = style.ThemeSectionServicios.Tarjeta.Titulo.css.Color
const estiloTarjetaTituloShadow = style.ThemeSectionServicios.Tarjeta.Titulo.css.BackgroundColor ? "4px 2px 6px "+ style.ThemeSectionServicios.Tarjeta.Titulo.css.BackgroundColor:""
const estiloTarjetaTituloFont = style.ThemeSectionServicios.Tarjeta.Titulo.css.Font
const estiloTarjetaTituloSize = style.ThemeSectionServicios.Tarjeta.Titulo.css.Size
const estiloTarjetaTituloWeight = style.ThemeSectionServicios.Tarjeta.Titulo.css.Weight
const estiloTarjetaTituloAlign = style.ThemeSectionServicios.Tarjeta.Titulo.css.Align

const estiloTarjetaDescripcionColor = style.ThemeSectionServicios.Tarjeta.Descripcion.css.Color
const estiloTarjetaDescripcionShadow = style.ThemeSectionServicios.Tarjeta.Descripcion.css.BackgroundColor ? "4px 2px 6px "+ style.ThemeSectionServicios.Tarjeta.Descripcion.css.BackgroundColor:""
const estiloTarjetaDescripcionFont = style.ThemeSectionServicios.Tarjeta.Descripcion.css.Font
const estiloTarjetaDescripcionSize = style.ThemeSectionServicios.Tarjeta.Descripcion.css.Size
const estiloTarjetaDescripcionWeight = style.ThemeSectionServicios.Tarjeta.Descripcion.css.Weight
const estiloTarjetaDescripcionAlign = style.ThemeSectionServicios.Tarjeta.Descripcion.css.Align

const estiloTarjetaFondoColor = "linear-gradient(to right," +style.ThemeSectionServicios.Tarjeta.FondoCSS.BackgroundColor+","+style.ThemeSectionServicios.Tarjeta.FondoCSS.BackgroundColorGradient+")" 
</script>
<style lang="scss">

  .card-servicios-notin h3, .card-servicios-notin h5{
    color:v-bind(estiloTarjetaTituloColor)  !important;
    text-shadow:v-bind(estiloTarjetaTituloShadow)  !important;
    font-size:v-bind(estiloTarjetaTituloSize) !important;
    font-family: v-bind(estiloTarjetaTituloFont) !important;
    font-weight:v-bind(estiloTarjetaTituloWeight) !important;
    text-align:v-bind(estiloTarjetaTituloAlign)  !important;
  }

  .card-servicios-notin p{
    color:v-bind(estiloTarjetaDescripcionColor)  !important;
    text-shadow:v-bind(estiloTarjetaDescripcionShadow)  !important;
    font-family:v-bind(estiloTarjetaDescripcionFont)  !important;
    font-size: v-bind(estiloTarjetaDescripcionSize) !important;
    font-weight:v-bind(estiloTarjetaDescripcionWeight) !important;
    text-align:v-bind(estiloTarjetaDescripcionAlign)  !important;
  }
  .card-servicios-notin a:before{
    background:v-bind(estiloTarjetaFondoColor)  !important;
  }
</style>